import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import g from 'glamorous'
import { css } from 'glamor'
import Layout from '../components/Layout'
import { IoIosBook } from 'react-icons/io'
import { Helmet } from 'react-helmet'

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Publications | Valerie Pasquiou Interiors & Design</title>
        <meta
          name="description"
          content="Articles in the press about our projects of interior design, interior architecture and space planning consulting"
        />
      </Helmet>
      <section>
        <H1>Press</H1>
        <Wrapper>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Item key={node.id}>
              <Link to={node.fields.slug} {...cssLink}>
                <Img
                  fluid={node.frontmatter.image.childImageSharp.fluid}
                  css={{}}
                />
                <Caption className="caption">
                  <IoIosBook />
                  <div>Read</div>
                </Caption>

                <H2>{node.frontmatter.title}</H2>
                <Date>
                  {node.frontmatter.date} | {node.frontmatter.country}
                </Date>
              </Link>
            </Item>
          ))}
        </Wrapper>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "presse" } } }
      sort: { fields: [frontmatter___poids], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            type
            poids
            date
            country
            pdf
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

const Wrapper = g.div({
  marginTop: `85px`,
  marginBottom: `120px`,
  display: `grid`,
  gridTemplateColumns: `21% 21% 21% 21% `,
  gridGap: `5%`,
  paddingTop: `12px`,
  '@media(max-width: 768px)': {
    marginTop: `10px`,
    padding: `0 15px`,
    gridTemplateColumns: `48% 48%`,
    rowGap: `20px`,
  },
})

const H1 = g.h1({
  '@media(min-width: 768px)': {
    display: `none !important`,
  },
  padding: `0 15px .35em`,
  display: `block`,
  margin: `.8em 0 .3em`,
  fontSize: `2em`,
  marginTop: `0`,
})

const Item = g.div({
  marginBottom: `1em`,
  position: `relative`,
  '& h2': {
    borderBottom: `.08em solid white`,
    transition: `all .25s ease-in-out`,
  },
  '& .caption': {
    display: `none`,
    opacity: `0`,
    transition: `all .25s ease-in-out`,
  },

  ':hover': {
    '& h2': {
      borderBottom: `.08em solid #1f1f1f`,
      transition: `all .25s ease-in-out`,
    },
    '& .caption': {
      display: `block`,
      opacity: `.8`,
      transition: `all .25s ease-in-out`,
    },
  },
  '@media(max-width: 768px)': {
    ':hover': {
      '& h2': {
        borderBottom: `.08em solid white`,
        transition: `all .25s ease-in-out`,
      },
    },
  },
})

const Caption = g.div({
  position: `absolute`,
  top: `0`,
  backgroundColor: `white`,
  width: `100%`,
  height: `52%`,
  textAlign: `center`,
  paddingTop: `50%`,
  color: `black`,
  fontSize: `1.4em`,
  transition: `all .25s ease-in-out`,
  '@media(max-width: 768px)': {
    display: `none !important`,
  },
})

const cssLink = css({
  textDecoration: `none`,
})

const H2 = g.h2({
  color: `#1f1f1f`,
  margin: `.7em 0em .05em`,
  fontSize: `1.35em`,
  letterSpacing: `.1em`,
  display: `inline-block`,
  paddingBottom: `2px`,
  '@media(max-width: 768px)': {
    fontSize: `1.2em`,
    letterSpacing: `.08em`,
    paddingBottom: `0`,
    marginBottom: `0`,
  },
})

const Date = g.div({
  color: `rgba(31,31,31,.5)`,
  fontSize: `.87em`,
  letterSpacing: `.015em`,
  '@media(max-width: 768px)': {
    fontSize: `.82em`,
  },
})
